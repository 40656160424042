/* eslint-disable prefer-destructuring */
import { store } from '@redux/store'
import _get from 'lodash/get'
import {
  setOrder,
  setDeliveryCalendar as setDeliveryCalendarRedux,
  setDeliveryInvalidFields,
} from '@redux/modules/checkout'
import {
  PICK_UP,
  DOORWAY_DELIVERY,
} from '@components/checkout/checkout-parts/delivery-section/delivery-modal/constants'
import { getFromBrowserStorage } from '@helpers/storage'
import { getAddressSpecificBody, alertAndReturnToCart } from '@helpers/checkout/shipping-section'
import { updateDelivery } from '@services/checkout'
import { sentryLogger, levels, sentryMessages, setExtra } from '@helpers/sentry-logger'
import { getStore, getStoreHours } from '../store-locator'
import { months, weekdays, getStandardTime } from '../string-helper'
import { getDateFull } from '../date'
import { getOrder } from './global'

export const getDistributionCenter = order => {
  const orderStore = getStore(order)
  if (orderStore && orderStore.pickup_hours) {
    return {
      distributionCenter: {
        name: `${orderStore.city} Distribution Center`,
        hours: getStoreHours(orderStore.pickup_hours),
      },
    }
  }
  return {}
}

export const getDeliverySliderIndex = (selectedDate, dates) => {
  let index = 0
  if (dates.filter(date => date.isExpressDelivery).length > 0) {
    if (dates.filter(date => date.isStandardDelivery).findIndex(date => date.readable === selectedDate.readable) > 1) {
      index = dates.filter(date => date.isStandardDelivery).findIndex(date => date.readable === selectedDate.readable)
    }
  }
  return index
}

export const splitDates = date => {
  const d = date.split('-')
  d.map(mappedDate => parseInt(mappedDate))

  const deliverDate = new Date()
  deliverDate.setFullYear(d[0])
  deliverDate.setMonth(d[1] - 1)
  deliverDate.setDate(d[2])

  const fortNight = new Date(deliverDate)
  fortNight.setDate(d[2] + 14)

  return { deliverDate, fortNight }
}

/**
 *
 * @param {object} order the order object
 * @param {string} deliveryMode
 * @returns {object} request body for updateDelivery call
 */
export const getDeliverySpecificBody = (order, deliveryMode) => {
  let deliveryType = 'U'
  const { lineItems } = order
  const storeCartLineItems = order && order.lineItems ? order.lineItems.filter(lineItem => lineItem.isStoreSku) : []
  const onlineCartLineItems = order && order.lineItems ? order.lineItems.filter(lineItem => !lineItem.isStoreSku) : []

  if (storeCartLineItems?.length > 0 && onlineCartLineItems?.length === 0) {
    // only storeCartItems
    deliveryType = storeCartLineItems[0].deliveryType
  }

  lineItems.forEach(item => {
    if (item.deliveryType === 'D' || item.deliveryType === 'K') {
      deliveryType = 'D'
    }
  })
  const body = {
    orderId: order.orderId,
    additionalDirections: order.additionalDirections,
    shouldCombineUPSWithTruck: true,
    deliveryType,
    isPickup: deliveryMode ? deliveryMode === PICK_UP : !!order?.isPickup,
    doorwayDelivery: deliveryMode ? deliveryMode === DOORWAY_DELIVERY : !!order?.doorwayDelivery,
  }
  if (deliveryType === 'D') {
    body.deliveryDate = order.deliveryDate || order.deliveryCalendar[0]
  }

  if (deliveryType === 'P') {
    body.deliveryDate = order.deliveryDate || order.pickupCalendar[0]
  }

  if (deliveryType === 'E') {
    body.deliveryDate = order.deliveryDate || _get(order, 'expressCalendar.0')
  }

  if (order?.splitDelivery) {
    body.splitDelivery = order.splitDelivery
  }

  if (!order.splitDelivery && order.splitDeliveryDates) {
    body.splitDelivery = order.splitDeliveryDates.map(({ deliveryDate }) => deliveryDate)
  }

  return body
}

export const setDeliveryInfo = (info, field) => {
  let order = getOrder()
  order = {
    ...order,
    [field]: info,
  }
  store.dispatch(setOrder(order))
}

export const setDeliveryCalendar = (deliveryCalendar, pickupCalendar, expressCalendar, setCalendar = true) => {
  const calendar = []
  if (expressCalendar && expressCalendar.length) {
    expressCalendar.forEach(expressDelivery => {
      const expressDate = new Date(expressDelivery).setHours(new Date(expressDelivery).getHours() + 6)
      calendar.push({
        date: expressDelivery,
        readable: `${months[Number(expressDelivery.substr(5, 2)) - 1]} ${expressDelivery.substr(8, 2)}`,
        dayOfWeek: weekdays[new Date(expressDate).getDay()].substr(0, 3),
        isPickup: false,
        isStandardDelivery: true,
        isExpressDelivery: true,
      })
    })
  }
  for (let i = 0, n = deliveryCalendar.length; i < n; i++) {
    const date = new Date(deliveryCalendar[i]).setHours(new Date(deliveryCalendar[i]).getHours() + 6)
    calendar.push({
      date: deliveryCalendar[i],
      readable: `${months[Number(deliveryCalendar[i].substr(5, 2)) - 1]} ${deliveryCalendar[i].substr(8, 2)}`,
      dayOfWeek: weekdays[new Date(date).getDay()].substr(0, 3),
      isPickup: false,
      isStandardDelivery: true,
      isExpressDelivery: false,
    })
  }
  if (pickupCalendar) {
    for (let i = 0, n = pickupCalendar.length; i < n; i++) {
      const date = new Date(pickupCalendar[i]).setHours(new Date(pickupCalendar[i]).getHours() + 6)
      const index = calendar.findIndex(calendarDate => calendarDate.date === pickupCalendar[i])
      if (index !== -1) {
        calendar[index].isPickup = true
      } else {
        calendar.push({
          date: pickupCalendar[i],
          readable: `${months[Number(pickupCalendar[i].substr(5, 2)) - 1]} ${pickupCalendar[i].substr(8, 2)}`,
          dayOfWeek: weekdays[new Date(date).getDay()].substr(0, 3),
          isPickup: true,
          isStandardDelivery: false,
          isExpressDelivery: false,
        })
      }
    }
  }
  calendar.sort((a, b) => {
    if (a.date < b.date) {
      return -1
    }
    if (a.date > b.date) {
      return 1
    }
    return 0
  })
  if (setCalendar) store.dispatch(setDeliveryCalendarRedux(calendar))
  return calendar
}

export const getRoomsToGoDeliveryItems = lineItems => {
  const deliveryItems = []
  for (let i = 0, n = lineItems?.length; i < n; i++) {
    if (
      (lineItems[i].deliveryType === 'D' ||
        lineItems[i].deliveryType === 'E' ||
        lineItems[i].deliveryType === 'K' ||
        lineItems[i].deliveryType === 'P') &&
      lineItems[i].sku !== '99999925'
    ) {
      deliveryItems.push(lineItems[i])
    }
  }
  return deliveryItems
}

export const getVendorDeliveryItems = lineItems => {
  const deliveryItems = []
  for (let i = 0, n = lineItems?.length; i < n; i++) {
    if (lineItems[i].deliveryType === 'O') {
      deliveryItems.push(lineItems[i])
    }
  }
  return deliveryItems
}

export const getUPSDeliveryItems = lineItems => {
  const deliveryItems = []
  for (let i = 0, n = lineItems?.length; i < n; i++) {
    if (lineItems[i].deliveryType === 'U') {
      deliveryItems.push(lineItems[i])
    }
  }
  return deliveryItems
}

export const getUSPSDeliveryItems = lineItems => {
  const deliveryItems = []
  for (let i = 0, n = lineItems?.length; i < n; i++) {
    if (lineItems[i].deliveryType === 'T') {
      deliveryItems.push(lineItems[i])
    }
  }
  return deliveryItems
}

export const getDeliveryDate = (order, deliveryCalendar, pickup = false) => {
  if (order && deliveryCalendar) {
    let deliveryDate
    if (order.deliveryDate) {
      if ((order.isPickup && pickup) || (!order.isPickup && !pickup)) {
        deliveryDate = deliveryCalendar.filter(date => date.date === order.deliveryDate)[0]
      } else if (!order.isPickup && pickup) {
        deliveryDate = deliveryCalendar.filter(date => date.isPickup)[0]
      } else {
        deliveryDate = deliveryCalendar.filter(date => date.isStandardDelivery && !date.isExpressDelivery)[0]
      }
    } else if (pickup) {
      deliveryDate = deliveryCalendar.filter(date => date.isPickup)[0]
    } else {
      deliveryDate = deliveryCalendar.filter(date => date.isStandardDelivery && !date.isExpressDelivery)[0]
    }
    if (deliveryCalendar.length > 0 && deliveryDate) {
      let storedDate
      let expressDate
      if (order.isPickup) {
        storedDate = deliveryCalendar.filter(date => date.isPickup)[0]
      } else {
        const expressDates = deliveryCalendar.filter(date => date.isExpressDelivery)
        expressDate = expressDates.length > 0 ? expressDates[0] : null
        storedDate = deliveryCalendar.filter(date => date.isStandardDelivery && !date.isExpressDelivery)[0]
      }

      const selectedDate = deliveryDate.date
      if (deliveryDate.readable !== '' && selectedDate >= ((expressDate && expressDate.date) || storedDate.date)) {
        return {
          ...deliveryDate,
        }
      }
      return {
        ...deliveryDate,
      }
    }
    return {
      readable: 'No delivery dates avaliable.',
      date: new Date().toISOString().split('T')[0],
    }
  }
  return {}
}

export const getDistributionCenterHours = (order, date) => {
  const thisDate = new Date(date.date).setHours(new Date(date.date).getHours() + 6)
  const day = weekdays[new Date(thisDate).getDay()]
  if (order) {
    const location = getStore(order)
    if (location && day) {
      const { pickup_hours } = location
      const dayHours = {
        openTime: pickup_hours[`${day.toLowerCase()}Open`],
        closeTime: pickup_hours[`${day.toLowerCase()}Closed`],
      }
      if (dayHours && dayHours.openTime)
        return `${getStandardTime(dayHours.openTime)}-${getStandardTime(dayHours.closeTime)}`.toUpperCase()
    }
  }
  return ''
}

export const getDistributionDaysClosed = order => {
  if (order) {
    const daysClosed = []
    for (let i = 0, n = weekdays.length; i < n; i++) {
      const orderStore = getStore(order)
      if (orderStore) {
        const { pickup_hours } = orderStore
        if (pickup_hours && !pickup_hours[`${weekdays[i].toLowerCase()}Open`]) {
          daysClosed.push(weekdays[i].substr(0, 3))
        }
      }
    }
    if (daysClosed.length === 1) {
      return daysClosed[0]
    }
    if (daysClosed.length === 2) {
      return `${daysClosed[0]} and ${daysClosed[1]}`
    }
    let str = ''
    for (let i = 0, n = daysClosed.length; i < n; i++) {
      if (i === n - 1) str = `${str}and ${daysClosed[i]}`
      else str = `${str}${daysClosed[i]}, `
    }
    return str
  }
  return ''
}

const getStoreCartDeliveryDate = storeCart => {
  let deliveryDate = null
  if (storeCart && storeCart.lineItems && storeCart.lineItems?.length) {
    deliveryDate = storeCart.lineItems[0].deliveryDate
  }

  return deliveryDate
}

export const getDeliverySectionData = (order, deliveryCalendar) => {
  let rtgDeliveryItems = []
  let vendorDeliveryItems = []
  let upsDeliveryItems = []
  let uspsDeliveryItems = []
  if (order && order.lineItems) {
    rtgDeliveryItems = getRoomsToGoDeliveryItems(order.lineItems)
    vendorDeliveryItems = getVendorDeliveryItems(order.lineItems)
    upsDeliveryItems = getUPSDeliveryItems(order.lineItems)
    uspsDeliveryItems = getUSPSDeliveryItems(order.lineItems)
  }
  const deliveryDate =
    (order && order.deliveryDate) ||
    getStoreCartDeliveryDate(order.storeCart) ||
    (deliveryCalendar.length > 0 &&
      deliveryCalendar.filter(date => date.isStandardDelivery && !date.isExpressDelivery)[0].date)
  const isExpress =
    deliveryCalendar.length > 0 &&
    deliveryDate &&
    `${deliveryDate}` &&
    deliveryCalendar.filter(day => day.isExpressDelivery).length > 0 &&
    deliveryCalendar.filter(day => day.isExpressDelivery && day.date === deliveryDate).length === 1
  let fullDeliveryDate

  if (deliveryDate) {
    fullDeliveryDate = getDateFull(deliveryDate)
  }

  return {
    rtgDeliveryItems,
    vendorDeliveryItems,
    uspsDeliveryItems,
    upsDeliveryItems,
    isExpress,
    fullDeliveryDate,
  }
}

const noSaleZip = ['00901', '00902', '00775', '00765']
const noSaleZipMsg = `Old San Juan, Vieques, Culebra & US Virgin Islands customers, please contact our Online Sales Team to place an order.  Write us via WhatsApp for assistance with your order. 
(787) 293-7715`

const invalidZip = () => {
  alertAndReturnToCart(noSaleZipMsg)
}

// eslint-disable-next-line
export let validateDeliveryStep = async nextStep => {
  let invalidFields = []
  const order = getOrder()
  await updateDelivery(getDeliverySpecificBody(order, order.deliveryMode))
    .then(data => {
      store.dispatch(setOrder(data))
    })
    .catch(() => {
      sentryLogger({
        configureScope: {
          type: setExtra,
          level: levels.error,
          orderId: order.orderId,
          section: 'Checkout: Delivery Section',
        },
        captureMessage: {
          message: sentryMessages.updateDeliveryFailure,
          level: levels.error,
        },
      })
    })
  if (noSaleZip.includes(order.shippingAddress.zip)) invalidZip()

  if (nextStep === 'review') {
    invalidFields = ['payment incomplete']
  }
  store.dispatch(setDeliveryInvalidFields(invalidFields))
  return invalidFields
}

export const canSkipDelivery = async () => {
  const order = getOrder()
  const rtgDeliveryItems = getRoomsToGoDeliveryItems(order?.lineItems)
  if (rtgDeliveryItems?.length < 1 && !getFromBrowserStorage('session', 'storecart')) {
    await validateDeliveryStep('payment')
    return true
  }
  return false
}
