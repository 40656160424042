import { navigate } from 'gatsby'
import { format } from 'date-fns'
import { getStateEligiblity } from '@helpers/extendedServiceContract'
import { store } from '@redux/store'
import { setOrder, setShippingInvalidFields, setCheckoutStepsCompleted, setCheckoutStep } from '@redux/modules/checkout'
import { sentryLogger, levels, sentryMessages, setExtra } from '@helpers/sentry-logger'
import { setLocation } from '@redux/modules/location'
import { updateAddress, fetchAddressLookup, fetchAddressFormat } from '@services/checkout'
import { getLocation } from '@services/location'
import { getFromBrowserStorage } from '@helpers/storage'
import { identifyUser } from '@components/integrations/Segment'
import { isValidPhoneNumber, parsePhoneNumberFromString } from 'libphonenumber-js'
import { getOrder, checkManualAddress } from './global'
import { abbreviateState, getCurrentLocation, getRegionZone } from '../geo-location'
import { validateEmail, validatePhone, validateZip } from '../string-helper'
import { checkProductRegionAvailability, verifyAndUpdateCart, getCart } from '../cart'
import { setBillingAddressInfo } from './payment-section/billing-address'
import { announce, taskDone } from '../aria-announce'
import { orderHasSomeWarranty } from './review-section'

const regionInPR = getRegionZone().region === 'PR'

const noSaleStates = ['CA', 'AK', 'HI']
const noSaleZip = ['00901', '00902', '00775', '00765']
const noSaleZipMsg = `Old San Juan, Vieques, Culebra & US Virgin Islands customers, please contact our Online Sales Team to place an order.  Write us via WhatsApp for assistance with your order. 
(787) 293-7715`

if (!regionInPR) {
  noSaleStates.push('PR')
}

export const setStoreInfo = ({
  salesPersonInfo,
  storeAddress,
  storeName,
  storeNumber,
  storePhoneNumber,
  storeCartId,
}) => {
  let order = getOrder()
  order = {
    ...order,
    storeInfo: {
      salesPersonInfo,
      storeAddress,
      storeName,
      storeNumber,
      storePhoneNumber,
      storeCartId,
    },
  }
  store.dispatch(setOrder(order))
}

export const setContactInfo = (info, field = '') => {
  let order = getOrder()

  if (!field) {
    order = {
      ...order,
      contact: {
        ...info,
      },
    }
  } else if (Object.is(field, 'phone') && info?.length > 9) {
    const phoneNum = parsePhoneNumberFromString(info, 'US')
    order = {
      ...order,
      contact: {
        ...order.contact,
        [field]: phoneNum.nationalNumber,
      },
    }
  } else {
    order = {
      ...order,
      contact: {
        ...order.contact,
        [field]: info,
      },
    }
  }

  store.dispatch(setOrder(order))
}

export const setShippingAddressInfo = (info, field = '') => {
  let order = getOrder()
  let newInfo = info
  if (info.state) {
    info.state = abbreviateState(info.state)
  } else if (field === 'state') {
    newInfo = abbreviateState(info)
  }
  if (field) {
    order = {
      ...order,
      shippingAddress: {
        ...order.shippingAddress,
        [field]: newInfo,
      },
    }
  } else {
    order = {
      ...order,
      shippingAddress: {
        ...order.shippingAddress,
        ...newInfo,
      },
    }
  }
  store.dispatch(setOrder(order))
}

// eslint-disable-next-line
export let validateShippingStep = async nextStep => {
  const order = getOrder()
  let invalidFields = []
  if (nextStep === 'payment') {
    invalidFields = ['delivery incomplete']
  }
  if (nextStep === 'review') {
    invalidFields = ['payment incomplete']
  }

  const entries = Object.entries({ ...order.contact, ...order.shippingAddress })
  for (let i = 0, n = entries.length; i < n; i++) {
    const entryKey = entries[i][0]
    const entryData = entries[i][1]
    if (
      (entryData === '' || entryData === null) &&
      entryKey !== 'address2' &&
      entryKey !== 'altPhone' &&
      entryKey !== 'country_code' &&
      entryKey !== 'plus4' &&
      entryKey !== 'county' &&
      entryKey !== 'addressLookup'
    ) {
      if (entryKey === 'address1') {
        invalidFields.push('address1')
      } else {
        invalidFields.push(entryKey)
      }
    } else {
      if (entryKey === 'email' && !validateEmail(entryData)) {
        invalidFields.push('email')
      }
      if (entryKey === 'phone' && !isValidPhoneNumber(entryData, 'US')) {
        invalidFields.push('phone')
      }
      if (entryKey === 'altPhone' && entryData !== '' && !isValidPhoneNumber(entryData, 'US')) {
        invalidFields.push('alternatePhone')
      }
      if (entryKey === 'zip' && !validateZip(entryData)) {
        invalidFields.push('zip')
      }
      if (order.shippingAddress.showAddressLookup && entryKey === 'addressLookupSuccess' && !entryData) {
        invalidFields.push('addressLookup')
      }
    }
  }
  if (order.shippingAddress.showAddressLookup) {
    invalidFields = invalidFields.filter(entry => entry !== 'address1')
    invalidFields = invalidFields.filter(entry => entry !== 'city')
    invalidFields = invalidFields.filter(entry => entry !== 'state')
    invalidFields = invalidFields.filter(entry => entry !== 'zip')
  } else {
    const stepOneManualEntry = getFromBrowserStorage('session', 'StepOneManualEntry')
    if (stepOneManualEntry.toString() === 'true') {
      invalidFields = []
    } else {
      invalidFields = await checkManualAddress(order, invalidFields)
    }
  }
  if (invalidFields.length < 1) {
    const isValidZipCode = await checkShippingZip(
      order.shippingAddress.addressLookupSuccess
        ? order.shippingAddress.addressLookup.slice(-5)
        : order.shippingAddress.zip,
      true,
      getCart(),
    )
    if (isValidZipCode) {
      let canUpdateAddr = true
      if (orderHasSomeWarranty(order) && order.shippingAddress.state) {
        const isEligibleState = getStateEligiblity(order.shippingAddress.state)
        canUpdateAddr = isEligibleState
        if (!isEligibleState) {
          alertAndReturnToCart(
            'We apologize, but we cannot sell the furniture protection plan in the specified delivery zip code. It has been removed from your cart.',
          )
        }
      }
      if (canUpdateAddr) {
        // Segment Identify call
        await identifyUser({
          email: order?.contact?.email,
          zipcode: order?.shippingAddress?.zip,
          subsourcecode: 'rtgcheckout',
          signup: order.emailCampaign,
        })
        const addrOrder = await updateAddress(getAddressSpecificBody(order)).catch(() => {
          sentryLogger({
            configureScope: {
              type: setExtra,
              level: levels.error,
              orderId: order.orderId,
              section: 'Checkout: Delivery Section',
            },
            captureMessage: {
              message: sentryMessages.updateAddressFailure,
              level: levels.error,
            },
          })
          invalidFields = ['buttonClick']
        })
        // Check if a delivery calendar exists for product. If an empty array is returned then proceed to button click
        if (addrOrder) {
          const newOrder = { ...checkOrderDeliveryDate(addrOrder), deliveryDate: order.deliveryDate }
          if (order?.expressCalendar?.includes(order.deliveryDate)) {
            store.dispatch(setOrder({ ...newOrder, deliveryDate: order.deliveryDate }))
          }
          store.dispatch(setOrder({ ...checkOrderDeliveryDate(addrOrder), acceptManual: false }))
        } else {
          invalidFields = ['buttonClick']
        }
      }
    } else {
      invalidFields = ['zipCode']
    }
  }
  store.dispatch(setShippingInvalidFields(invalidFields))
  return invalidFields
}

export const checkOrderDeliveryDate = order => {
  if (
    (order.deliveryCalendar &&
      order.deliveryCalendar[0] &&
      !order.deliveryCalendar.includes(order.deliveryDate) &&
      !order.isPickup) ||
    (order.pickupCalendar && !order.pickupCalendar.includes(order.deliveryDate) && order.isPickup)
  ) {
    const [deliveryDate] = order.deliveryCalendar
    order.deliveryDate = deliveryDate
    order.isPickup = false
  }

  if (format(new Date(order.deliveryDate.replaceAll('-', '/')), 'EEEE') === 'Sunday') {
    order.deliveryDate = order.deliveryCalendar.find(
      date => format(new Date(date.replaceAll('-', '/')), 'EEEE') !== 'Sunday',
    )
  }

  return order
}

export const getAddressSpecificBody = (order, billing = false) => {
  const location = getCurrentLocation()
  const zipParts = order.shippingAddress.zip.split('-')
  let body = {
    orderId: order.orderId,
    shippingAddress: {
      ...order.shippingAddress,
      county: (location && location.county) || 'U',
      country_code: 'US',
      zip: zipParts[0],
      plus4: zipParts[1],
    },

    contact: {
      ...order.contact,
      phone: order.contact.phone.replace(/\D+/g, ''),
      altPhone: order.contact.altPhone && order.contact.altPhone.replace(/\D+/g, ''),
    },
    deliveryTexts: order.deliveryTexts,
    emailCampaign: order.emailCampaign,
    distributionIndex: location && parseInt(location.distribution_index),
  }
  if (billing) {
    body = {
      ...body,
      billingAddress: {
        ...order.billingAddress,
        county: (location && location.county) || 'U',
        country_code: 'US',
      },
      payer: {
        ...order.payer,
      },
    }
  }
  return body
}

export const checkShippingZip = async zipIn => {
  const data = await getLocation(zipIn)
  if (data && data.response) {
    const location = getCurrentLocation()
    const filterStates = ['California', 'Alaska', 'Hawaii']
    if (!regionInPR) {
      filterStates.push('Puerto Rico')
    }
    if (filterStates.includes(data.response.state.trim())) {
      alertLocationIssue()
      return false
    }
    if (noSaleZip.includes(data.response.zip)) {
      alertAndReturnToCart(noSaleZipMsg)
      return false
    }
    if (
      location.region !== data.response.region ||
      location.delivery_zone !== data.response.delivery_zone ||
      location.price_zone !== data.response.price_zone ||
      location.state !== data.response.state
    ) {
      store.dispatch(setLocation(data.response))
      await verifyAndUpdateCart(getCart())
    }

    const notAvailable = await checkProductRegionAvailability(data.response.region)
    if (notAvailable.length) {
      store.dispatch(setCheckoutStepsCompleted({ shipping: false, delivery: false, payment: false }))
      alertAndReturnToCart(
        'We apologize, but some items are out of stock or unavailable for purchase in the specified zip code. Please review your cart and make changes as necessary.',
      )
    }
  }
  return true
}

export const alertAndReturnToCart = alertMsg => {
  // eslint-disable-next-line no-alert
  alert(alertMsg)
  store.dispatch(setCheckoutStep('shipping'))
  // TODO: navigation need to add code to track ga data

  navigate('/cart')
}

// eslint-disable-next-line
export let setAddress = async (address, billing = false, formattedAddress = {}) => {
  if (address !== 'No matches.') {
    const { address1, address2, city, state, zip, plus4, globalAddressId } = formattedAddress
    let addressArr = address.split(',')
    const address1Unformatted = addressArr[0]
    addressArr = addressArr[1].split(' ')
    let unformattedCity = ''
    for (let i = 1; i < addressArr.length - 2; i++) {
      unformattedCity = `${unformattedCity} ${addressArr[i]}`
    }
    unformattedCity = unformattedCity.trim()
    const zipArr = addressArr[addressArr.length - 1].split('-')
    if (billing) {
      setBillingAddressInfo({
        addressLookup: address,
        addressLookupSuccess: true,
        address1: address1 || address1Unformatted,
        address2: address2 || '',
        city: city || unformattedCity,
        state: state || addressArr[addressArr.length - 2],
        zip: zip || zipArr[0],
        plus4: plus4 || zipArr[1],
        globalAddressId,
      })
    } else {
      setShippingAddressInfo({
        addressLookup: address,
        addressLookupSuccess: true,
        address1: address1 || address1Unformatted,
        address2: address2 || '',
        city: city || unformattedCity,
        state: state || addressArr[addressArr.length - 2],
        zip: zip || zipArr[0],
        plus4: plus4 || zipArr[1],
        globalAddressId,
      })
    }
  } else {
    setShippingAddressInfo(false, 'showAddressLookup')
  }
}

export const onAddressLookupChange = (address, setAddressItems) => {
  const order = getOrder()
  const addressItems = []
  fetchAddressLookup(address)
    .then(data => {
      if (data.results && data.results.length > 0) {
        data.results.forEach((result, index) => {
          addressItems.push({
            id: index,
            label: result.suggestion,
            globalAddressId: result.format.split('id=')[1],
          })
        })
        addressItems.push({
          id: 'no results',
          label: '',
        })
        announceResults(data.results.length, address)
      } else {
        addressItems.push({
          id: 'no results',
          label: 'No matches.',
        })
        announceResults(0, address)
      }
      setAddressItems(addressItems)
    })
    .catch(error => {
      if (address.length > 0) {
        addressItems.push({
          id: 'error',
          label: 'No matches.',
        })
      }
      sentryLogger({
        configureScope: {
          type: setExtra,
          level: levels.error,
          orderId: order.orderId,
          error,
        },
        captureMessage: {
          type: 'text',
          message: sentryMessages.QasFailure,
          level: levels.error,
        },
      })
      announceResults(0, address)
      setAddressItems(addressItems)
    })
}

export const onAddressLookupSelect = async (selected, _autocomplete, changeAddressBtn, globalAddressId) => {
  if (selected !== 'No matches.' && selected !== '') {
    const address = await fetchAddressFormat(globalAddressId)
    const addressArr = selected.split(',')[1].split(' ')
    const autoApi = _autocomplete
    const state = (address && address.province) || addressArr[addressArr.length - 2]
    const formattedAddress = {
      address1: address.addressLine1,
      address2: address.addressLine2,
      city: address.locality,
      plus4: address.postalCode.split('-')[1],
      state,
      zip: address.postalCode.split('-')[0],
      globalAddressId,
    }
    if (noSaleStates.includes(state)) {
      alertLocationIssue()
    } else {
      await setAddress(selected, false, formattedAddress)
      taskDone(
        () => {
          autoApi._ignoreFocus = true
          autoApi._ignoreBlur = true
          changeAddressBtn.current.focus()
        },
        200,
        'focusChangeAddressBtn',
      )
    }
  }
}

export const onClickChangeAddress = (event, _autocomplete) => {
  event.preventDefault()
  const autoApi = _autocomplete

  if (event.target && event.target.className && event.target.className === 'change-address-btn') {
    setShippingAddressInfo({
      addressLookup: '',
      showAddressLookup: true,
      addressLookupSuccess: false,
    })

    taskDone(
      () => {
        if (autoApi) {
          autoApi._ignoreFocus = true
          autoApi.refs.input.select()
        } else {
          try {
            document.getElementById('address').focus()
          } catch (error) {}
        }
      },
      200,
      'focusAddressInput',
    )
  }
}

export const onStateChange = event => {
  if (event.target.value !== 'none') {
    if (noSaleStates.includes(event.target.value)) {
      alertLocationIssue()
    } else {
      setShippingAddressInfo(event.target.value, 'state')
    }
  }
}

export const onMenuVisibilityChange = (addressItems, setAddressItems) => {
  if (addressItems.length === 0) {
    setAddressItems([
      {
        id: 'no results',
        label: 'No matches.',
      },
    ])
  }
}

export const announceResults = (results, address) => {
  taskDone(
    () => {
      if (results > 0) {
        announce(`Displaying ${results + 1} Results for "${address}"`)
      } else {
        announce('No Matches. Press Tab to enter address manually.')
      }
    },
    350,
    'announceResultsCount',
  )
}

export const addressItemsFocus = (items, _autocomplete) => {
  if (items.length === 0) {
    _autocomplete._ignoreFocus = true
    _autocomplete.setState({
      highlightedIndex: null,
      isOpen: false,
    })
    _autocomplete.refs.input.select()
    _autocomplete.refs.input.setAttribute('aria-activedescendant', '')
  }
}

function alertLocationIssue() {
  // eslint-disable-next-line no-alert
  alert('We apologize, but we are currently unable to sell to Alaska, California, Hawaii, or Puerto Rico.')
}
